<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('recolte.produitCollecte') }}</h2>
    </div>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="desactives" border @change="getSubProduitCollecteList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterProduitCollecte">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubProduitCollecteList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterProduitCollecte" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterProduitCollecte">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer produit collecte'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateProduitCollecte">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">

        <el-table-column align="center" :label="$t('produitCollecte.campagne_agricole')">
          <template slot-scope="scope">
            <span>{{ scope.row.campagne_agricole.nom }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('produitCollecte.produit')" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.produit.nom }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['gerer produit'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setProduitCollecteStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkRole(['admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setProduitCollecteStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['modifier conteneur','visible conteneur','supprimer conteneur'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['modifier conteneur'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditProduitCollecte(scope.row.id);" />
            <el-button v-if="checkPermission(['visible conteneur','supprimer conteneur'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteProduitCollecte(scope.row.id, scope.row.is_visible, scope.row.num_cont);" />
          </template>
        </el-table-column>
      </el-table>
      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('produitCollecte.edit') : $t('produitCollecte.add')"
        :visible.sync="dialogCreateProduitCollecte"
        :before-close="handleClose"
        :loading="produitCollecteCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="produitCollecteForm" :model="currentProduitCollecte" label-position="left">
            <div style="display:flex; flex-direction:row">
              <el-form-item :label="$t('produitCollecte.produitCollecte')">
                <el-select v-model="currentProduitCollecteProduitId" style="width: 100%" placeholder="Select">
                  <el-option
                    v-for="(item, index) in produits"
                    :key="index"
                    style="width: 100%"
                    :label="item.nom"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateProduitCollecte=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="produitCollecteCreating" @click="editing ? updateProduitCollecte() : createProduitCollecte()">
              {{ produitCollecteCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const produitCollecteResource = new Resource('produit_recoltes');
const produitResource = new Resource('produits');

export default {
  name: 'ConteneursList',
  // components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      list: null,
      total: 0,
      loading: true,
      downloading: false,
      activeList: 'disponibles',
      produitCollecteCreating: false,
      editing: false,
      currentProduitCollecte: {},
      currentProduitCollecteProduitId: null,
      produits: [],
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      ci: '',
      dialogCreateProduitCollecte: false,
      rules: {
        num_cont: [{ required: true, message: this.$t('conteneur.NumCountRequired'), trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {

  },
  created() {
    this.getProduitsCollecteList();
    this.getProduitsList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleClose(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelproduitCollecteForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    resetNewProduitCollecte() {
      this.currentProduitCollecte = {
        id: '',
        campagne_agricole_id: '',
        produit_id: '',
      };
      this.currentProduitCollecteProduitId = '';
    },
    async getProduitsList() {
      const { data } = await produitResource.list();
      this.produits = await data;
    },
    initSearch(){
      this.handleFilterProduitCollecte();
    },
    async getProduitsCollecteList() {
      this.loading = true;
      const { data } = await produitCollecteResource.list(this.query);
      this.list = data.reverse();
      this.loading = false;
    },
    async getSubProduitCollecteList(subUrl) {
      this.loading = true;
      const { data } = await produitCollecteResource.sublist('/' + subUrl, this.query);
      if (subUrl === 'indexdesactive') {
        this.activeList = 'desactives';
      } else if (subUrl === 'indexinvisible') {
        this.activeList = 'corbeille';
      }
      this.list = data;
      this.loading = false;
    },
    getConteneurLink(id){
      this.$router.push({ path: '/conteneurs/' + id });
    },
    handleFilterProduitCollecte() {
      this.query.page = 1;
      this.getProduitsCollecteList();
    },
    handlecreateProduitCollecte() {
      this.editing = false;
      this.resetNewProduitCollecte();
      this.dialogCreateProduitCollecte = true;
      this.$nextTick(() => {
        this.$refs['produitCollecteForm'].clearValidate();
      });
    },
    handleEditProduitCollecte(id){
      this.editing = true;
      this.currentProduitCollecte = this.list.find(abonne => abonne.id === id);
      this.currentProduitCollecteProduitId = this.currentProduitCollecte.produit.id;
      this.dialogCreateProduitCollecte = true;
      console.log('currentProduitCollecte', this.currentProduitCollecte);
      this.$nextTick(() => {
        this.$refs['produitCollecteForm'].clearValidate();
      });
    },
    handleDeleteProduitCollecte(id, visible, num_cont) {
      console.log('id : ' + id + 'num_cont : ' + num_cont);
      this.$confirm(this.$t('produitCollecte.deleteWarn') + ' ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['gerer produit']))) {
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              produitCollecteResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('conteneur.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setProduitCollecteStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    async setProduitCollecteStatus(id, type){
      const data = await produitCollecteResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.activeList = 'disponibles';
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    createProduitCollecte() {
      this.produitCollecteCreating = true;
      this.currentProduitCollecte.produit_id = this.currentProduitCollecteProduitId;
      produitCollecteResource
        .store(this.currentProduitCollecte)
        .then(async(response) => {
          this.$message({
            message: this.$t('produitCollecte.iscreatedSuccessfully'),
            type: 'success',
            duration: 5 * 1000,
          });
          this.dialogCreateProduitCollecte = false;
          this.handleFilterProduitCollecte();
        })
        .catch(error => {
          this.$message({
            type: 'error',
            message: error,
          });
        })
        .finally(() => {
          this.produitCollecteCreating = false;
        });
    },
    updateProduitCollecte() {
      this.$refs['produitCollecteForm'].validate((valid) => {
        if (valid) {
          this.produitCollecteCreating = true;
          this.currentProduitCollecte.produit_id = this.currentProduitCollecteProduitId;
          produitCollecteResource.update(this.currentProduitCollecte.id, this.currentProduitCollecte).then(response => {
            this.handleFilterProduitCollecte();
            this.$message({
              message: this.$t('produitCollecte.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateProduitCollecte = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.produitCollecteCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('conteneur.num_cont'), this.$t('conteneur.num_pb'), this.$t('conteneur.pds_net')];
        const filterVal = ['id', 'num_cont', 'num_pb', 'pds_net'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des conteneurs SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
