<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('conteneur.conteneurs') }}</h2>
    </div>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="desactives" border @change="getSubConteneursList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterConteneurs">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubConteneursList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterConteneurs" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterConteneurs">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer conteneur'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateConteneur">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table v-loading="loading" :data="list" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" :label="$t('conteneur.num_cont')" width="80">
          <template slot-scope="scope">
            <span>{{ scope.row.num_cont }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('conteneur.num_pb')">
          <template slot-scope="scope">
            <span>{{ scope.row.num_pb }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('conteneur.pds_net')">
          <template slot-scope="scope">
            <span>{{ scope.row.pds_total }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('conteneur.nbr_sac')">
          <template slot-scope="scope">
            <span>{{ scope.row.nbr_sac }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setConteneurStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkRole(['admin']) && (activeList=='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setConteneurStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['modifier conteneur','visible conteneur','supprimer conteneur'])" align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['modifier conteneur'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditConteneur(scope.row.id);" />
            <el-button v-if="checkPermission(['visible conteneur','supprimer conteneur'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteConteneur(scope.row.id, scope.row.is_visible, scope.row.num_cont);" />
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.actions')">
          <template slot-scope="scope">
            <el-button type="info" size="small" icon="el-icon-view" @click="getConteneurLink(scope.row.id)" />
          </template>
        </el-table-column>
      </el-table>

      <!-- pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getConteneursList" /-->

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('conteneur.edit') + ' ' + currentConteneur.num_cont : $t('conteneur.AddConteneur')"
        :visible.sync="dialogCreateConteneur"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="conteneurCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="conteneurForm" :model="currentConteneur" label-position="left">
            <!-- <el-form-item :label="$t('conteneur.num_cont')" prop="num_cont">
              <el-input v-model="currentConteneur.num_cont" />
            </el-form-item> -->
            <el-form-item :label="$t('conteneur.num_pb')" prop="num_pb">
              <el-input v-model="currentConteneur.num_pb" />
            </el-form-item>
            <!-- <el-form-item :label="$t('conteneur.pds_net')" prop="pds_net">
              <el-input v-model="currentConteneur.pds_net" />
            </el-form-item> -->
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateConteneur=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="conteneurCreating" @click="editing ? updateConteneur() : createConteneur()">
              {{ conteneurCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const conteneurResource = new Resource('conteneurs');
export default {
  name: 'ConteneursList',
  // components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      list: null,
      total: 0,
      loading: true,
      downloading: false,
      activeList: 'disponibles',
      conteneurCreating: false,
      editing: false,
      currentConteneur: {},
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      ci: '',
      dialogCreateConteneur: false,
      currentConteneurId: 0,
      rules: {
        num_cont: [{ required: true, message: this.$t('conteneur.NumCountRequired'), trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {

  },
  created() {
    this.resetNewConteneur();
    this.getConteneursList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelconteneurForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    async setConteneurStatus(id, type){
      const data = await conteneurResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    initSearch(){
      this.handleFilterConteneurs();
    },
    async getConteneursList() {
      this.loading = true;
      const { data } = await conteneurResource.list(this.query);
      this.list = data.reverse();
      console.log('===== EDI');
      console.log(data);
      console.log('===== EVO');
      this.loading = false;
    },
    async getSubConteneursList(subUrl) {
      this.loading = true;
      const { data } = await conteneurResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    getConteneurLink(id){
      this.$router.push({ path: '/conteneurs/' + id });
    },
    handleFilterConteneurs() {
      this.query.page = 1;
      this.getConteneursList();
    },
    handlecreateConteneur() {
      this.editing = false;
      this.resetNewConteneur();
      this.dialogCreateConteneur = true;
      this.$nextTick(() => {
        this.$refs['conteneurForm'].clearValidate();
      });
    },
    handleEditConteneur(id){
      this.editing = true;
      this.resetNewConteneur();
      this.currentConteneur = this.list.find(abonne => abonne.id === id);
      this.dialogCreateConteneur = true;
      this.$nextTick(() => {
        this.$refs['conteneurForm'].clearValidate();
      });
    },
    handleDeleteConteneur(id, visible, num_cont) {
      console.log('id : ' + id + 'num_cont : ' + num_cont);
      this.$confirm(this.$t('conteneur.deleteWarn') + ' ' + num_cont + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer conteneur']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              conteneurResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('conteneur.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setConteneurStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createConteneur() {
      this.conteneurCreating = true;
      conteneurResource
        .store(this.currentConteneur)
        .then(async(response) => {
          this.$message({
            message: this.$t('conteneur.NewConteneur') + ' ' + this.currentConteneur.num_cont + ' ' + this.$t('conteneur.iscreatedSuccessfully'),
            type: 'success',
            duration: 5 * 1000,
          });
          this.currentConteneurId = response.data.id;
          this.resetNewConteneur();
          this.dialogCreateConteneur = false;
          this.handleFilterConteneurs();
        })
        .catch(error => {
          this.$message({
            type: 'error',
            message: error,
          });
        })
        .finally(() => {
          this.conteneurCreating = false;
        });
    },
    updateConteneur() {
      this.$refs['conteneurForm'].validate((valid) => {
        if (valid) {
          this.conteneurCreating = true;
          conteneurResource.update(this.currentConteneur.id, this.currentConteneur).then(response => {
            this.resetNewConteneur();
            this.handleFilterConteneurs();
            this.$message({
              message: this.$t('conteneur.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateConteneur = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.conteneurCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewConteneur() {
      this.currentConteneur = {
        num_cont: '',
        num_pb: '',
        pds_net: 0,
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('conteneur.num_cont'), this.$t('conteneur.num_pb'), this.$t('conteneur.pds_net')];
        const filterVal = ['id', 'num_cont', 'num_pb', 'pds_net'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des conteneurs SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
